<template>
    <div class="p-fluid">
        <div class="formgrid grid">
            <div class="field col-12 md:col-6 lg:col-3">
                <label>User</label>
                <Dropdown id="userweb_id" dataKey="userweb_id" v-model="forms.userweb_id" :loading="loadingDropdownUser"
                    :options="dataDropdownUser" :class="{ 'p-invalid': errorAdd.userweb_id }"
                    optionLabel="userweb_username" optionValue="userweb_id" placeholder="Select User" :filter="true"
                    :showClear="true" @filter="searchDropdownUser($event, 'add')"
                />
                <small class="p-invalid" style="color: red" v-if="errorAdd.userweb_id">{{
                    errorAdd.userweb_id[0]
                }}</small>
            </div>
        </div>
        <div class="formgrid grid">
            <div class="field col-12 md:col-6 lg:col-3">
                <label>Territory</label>
                <div class="field-checkbox mt-2">
                    <Checkbox id="is_all_area" v-model="checked" :binary="true" :disabled="forms.userweb_id == null" @change="this.forms.territory_id = [];"/>
                    <label for="is_all_area">Check if select all territory</label>
                </div>
                <Listbox v-model="forms.territory_id" :disabled="checked || forms.userweb_id == null" dataKey="territory_id" :options="dataDropdownTerritory" :class="{ 'p-invalid': errorAdd.territory_id }" :multiple="true" :filter="true" optionLabel="kabupaten_name" optionValue="territory_id" listStyle="max-height:250px" style="width:15rem" @filter="searchDropdownTerritory($event, 'add')">
                    <template #option="slotProps">
                        <div class="country-item">
                            <div>{{slotProps.option.territory_name}}</div>
                        </div>
                    </template>
                </Listbox>
                <small class="p-invalid" style="color: red" v-if="errorAdd.territory_id">{{
                    errorAdd.territory_id[0]
                }}</small>
            </div>
        </div>
    </div>
    <Button :loading="loading" label="Save" icon="pi pi-save" class="p-button-primary" @click="saveNew" />
</template>

<script>

import { mapGetters } from "vuex";

export default {
    emits: ["submit"],
    data() {
        return {
            // loading
            loading: false,
            loadingDropdownUser: false,
            loadingDropdownTerritory: false,

            // dataDropdown,
            dataDropdownUser: null,
            dataDropdownTerritory: null,

            // addNew
            forms: {
                userweb_id: null,
                territory_id: []
            },
            checked: false,
        }
    },
    mounted() {
        this.$store.commit('setErrorAdd', {});
        this.searchDropdownUser('');
        this.searchDropdownTerritory('');
    },
    computed:{
        ...mapGetters(['errorAdd']),
    },
    methods: {
        // DROPDOWN
        searchDropdownUser(event, purpose, valueEdit) {
            setTimeout(() => {

                if (valueEdit) {
                    this.$refs.duser.filterValue = valueEdit;
                }

                if (purpose == "add") {
                    this.loadingDropdownUser = true;
                }

                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/select2/user-web',
                    params: {
                        "search": valueEdit ? valueEdit : event.value,
                    }
                })
                .then(res => {

                    if (purpose == "add") {
                        this.dataDropdownUser = res.data.data;
                        this.loadingDropdownUser = false;
                    } else if (purpose == null) {
                        this.dataDropdownUser = res.data.data;
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
            }, 250);
        },
        searchDropdownTerritory(event, purpose, valueEdit) {
            setTimeout(() => {
                if (valueEdit) {
                    this.$refs.dterritory.filterValue = valueEdit;
                }

                if (purpose == "add") {
                    this.loadingDropdownTerritory = true;
                }

                this.axios({
                    method: 'GET',
                    url: process.env.VUE_APP_ROOT_API + 'web/select2/master-territory2',
                    params: {
                        "search": valueEdit ? valueEdit : event.value,
                    }
                })
                .then(res => {

                    if (purpose == "add") {
                        this.dataDropdownTerritory = res.data.data;
                        this.loadingDropdownTerritory = false;
                    } else if (purpose == null) {
                        this.dataDropdownTerritory = res.data.data;
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
            }, 250);
        },
        // ADDNEW
        saveNew() {
            this.loading = true;

            if(this.forms.territory_id.length == 0 && this.checked == false){
                alert("Please check or select in the available territory");
                this.loading = false;
                return;
            }

            this.axios({
                method: 'POST',
                url: process.env.VUE_APP_ROOT_API + 'web/master-userweb/create-area',
                data: {
                    "userweb_id": this.forms.userweb_id,
                    "territory_id": this.forms.territory_id,
                    "is_all_area": this.checked == true ? 'y' : 'n',
                },
            })
            .then((res) => {
                this.loading = false;
                if (res) {
                    this.$toast.add({ severity: 'success', summary: 'Successful', detail: 'Data Successfully Saved', life: 3000,});
                    this.$store.commit('setErrors', {});
                    this.$store.commit('setErrorAdd', {});
                    this.clearForms();
                    
                    this.$emit('submit');
                }
            })
            .catch((err) => {
                console.log(err);
                this.loading = false;
                this.$store.commit('setErrorAdd', err.response.data.data ? err.response.data.data : {});
                this.$toast.add({ severity: 'error', summary: 'Failed', detail: 'Data Failed to Saved', life: 3000,});
            });
        },
        clearForms() {
            this.forms.userweb_id = null;
            this.forms.territory_id = [];
            this.checked = false;
        },
    }
}
</script>